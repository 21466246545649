import React from "react"
import "../../theme/info.less"
import Container from "../../info/components/Container"
import Seo from "../../components/seo"
import MentorGuidelines from "../../info/components/MentorGuidelines"

const MentorGuidelinesPage = () => {
  return (
    <Container>
      <Seo title="Mentro: Learn with Mentors" />
      <MentorGuidelines />
    </Container>
  )
}

export default MentorGuidelinesPage
