import React from "react"
import { Typography, Row, Col, Button } from "antd"
import "./MentorGuidelines.less"

const { Title, Text } = Typography

export default function MentorGuidelines() {
  return (
    <div className="mentorGuidelinesParent">
      <div className="mentorGuidelinesDiv">
        <Title className="title mainTitle">Guidelines - Mentor</Title>
        <Text className="text">
          We at Mentro make sure it's easy to get started as a mentor. The
          following points will help you get started on the platform:
        </Text>
        <ul>
          <li>
            Students, Learners &#38; Job-Seekers can send you various types of
            session requests like Resume Review, Mock Interview, Placement
            Roadmap, Domain or Technical Doubts, Referral Session and more.
          </li>
          <li>
            Based on your knowledge, experience and specialization you can
            accept a session request and reply back or directly schedule a
            1-on-1 call.
          </li>
          <li>
            The 1-on-1 call is completely flexible for you and your mentee and
            can be taken on any platform like Google meet, Zoom, etc. Once you
            schedule a call, you'll be able to keep a track record of it in your
            Scheduled Calls screen. You'll also get notifications for the same.
          </li>
          <li>
            At the scheduled time of the call, you can just share a video call
            link over the started session (as a chat message). The Mentee would
            be able to join in and you can discuss and guide them out with
            clarity.
          </li>
          <li>
            You are free to discuss as much as you want through messages or
            schedule multiple calls for the same session in order to clarify if
            anything is still not clear for the mentee or any other technical or
            personal issues at the previous scheduled time.
          </li>
          <li>
            Once you and your mentee are satisfied with the session and the
            concerned doubt or query for that session has been clarified, you
            need to click on the End Session Button at top right of your
            session/chat screen.
          </li>
          <li>
            The Mentee then will be able to provide a rating for your sessions
            (in terms of stars), and that particular session will be officially
            ended.
          </li>
          <li>
            We highly recommend, to take up one kind of doubt/issue/query per
            session request. In fact that is exactly how it is supposed to be.
            Once that session's work is done, you should ideally hit the End
            Session and request for a rating of the session.
          </li>
        </ul>
      </div>
    </div>
  )
}
